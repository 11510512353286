import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, matchPath } from 'react-router';
import { NavLink as Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import cn from 'classnames';
import Media from 'react-media';

import { actions } from '../shared/store.js';
import logo from '../assets/icons/logo.png';
import { defaultSupportedLanguage } from '../shared/supported-languages.js';
import { addLanguageToPath } from '../shared/navigation-links.js';

import MainService from '../main-service.js';


import './navbar.scss';

class Navbar extends React.Component {
	static prefetchData = async language => MainService.getContactsData(language);

	state = {
		hasError: false,
	};

	componentDidMount() {
		const { mainData } = this.props;

		if (!mainData) {
			this.fetchData();
		}
	}

	handleNavigationClick = () => {
		const { closeNavbar } = this.props;
		closeNavbar();
	};

	handleNavbarMenu = () => {
		const { toggleNav } = this.props;
		toggleNav();
	};

	handleLang = (selectedLanguage) => {
		const {
			location: { pathname },
			history,
			changeLanguage,
		} = this.props;

		const match = matchPath(pathname, {
			path: '/:lang(pt)/*',
		});

		if (selectedLanguage.value === defaultSupportedLanguage.value && !!match) {
			// remove the language from the url if exists

			const newLocation = match.url.replace(`/${match.params.lang}/`, '/');
			history.push(newLocation);
		} else if (!match) {
			// add the language to the url

			const newLocation = `/${selectedLanguage.value}${pathname}`;
			history.push(newLocation);
		}

		changeLanguage(selectedLanguage);
	};

	fetchData() {
		const { language, setMainData } = this.props;
		Navbar.prefetchData(language.value)
			.then((data) => {
				setMainData(data);
			})
			.catch(() => {
				this.setState({
					hasError: true,
				});
			});
	}


	render() {
		const { hasError } = this.state;
		const {
			navbarOpen,
			mainData,
			location: { pathname },
		} = this.props;


		if (hasError || !mainData) {
			return null;
		}

		const { navbarImage } = mainData;

		return (
			<div className={`site-navbar ${cn({ open: navbarOpen })} `}>
				<div className="left-bar">
					<div className="content">
						<div className="logo">
							<NavLogo />
						</div>
						<div className="open-close">
							<div
								className={`burger ${cn({ open: navbarOpen })} `}
								onClick={this.handleNavbarMenu}
							>
								<span />
								<span />
								<span />
								<span />
								<span />
							</div>
						</div>
						<div className="actual-page">
							<NavigationPageName pathname={pathname} />
						</div>
					</div>
				</div>
				<div className="container-fluid right-bar">
					<div className="row content">
						<div className="col-xs-10 col-md-6 list">
							<NavList>
								<NavItem
									path={addLanguageToPath('/', pathname)}
									exact
									label="navigation.homepage"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={addLanguageToPath('/about-us', pathname)}
									label="navigation.about-us"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={addLanguageToPath('/portfolio', pathname)}
									label="navigation.portfolio"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={addLanguageToPath('/partner-with-us', pathname)}
									label="navigation.partner-with-us"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={addLanguageToPath('/stories', pathname)}
									label="navigation.stories"
									handleNavigationClick={this.handleNavigationClick}
								/>
								<NavItem
									path={addLanguageToPath('/contact-us', pathname)}
									label="navigation.contact-us"
									handleNavigationClick={this.handleNavigationClick}
								/>
							</NavList>
						</div>
						<Media query={{ 'min-width': '62em' }}>
							<div className=" image">
								{navbarImage.path && (
									<img
										className="nav-bar-image"
										src={`${process.env.REACT_APP_API_ENDPOINT + navbarImage.path}`}
										alt="navbar"
									/>
								)}
							</div>
						</Media>
						<div className="icon" />
					</div>
				</div>
			</div>
		);
	}
}

const NavLogo = () => (
	<Link to="/">
		 <img className="logo" src={logo} alt="Green Innovations" />
	</Link>
);

const NavList = ({ children, isOpen }) => (
	<nav className={`${cn({ open: isOpen })} nav-wrapper`}>
		<ul className="nav-list">{children}</ul>
	</nav>
);

const NavItem = ({
	path,
	label,
	externalLink,
	handleNavigationClick,
	exact,
}) => (
	<li className="nav-item">
		{!externalLink && (
			<Link
				to={path}
				exact={exact}
				onClick={handleNavigationClick}
				activeClassName="active"
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</Link>
		)}
		{externalLink && (
			<a
				href={path}
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleNavigationClick}
			>
				<FormattedMessage id={label}>
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
		)}
	</li>
);

const NavigationPageName = ({ pathname }) => {
	if (pathname === '/') {
		pathname = '/homepage';
	}
	const paths = pathname.split('/');

	return (
		<FormattedMessage id={`navigation.${paths[1]}`}>
			{txt => <Fragment>{txt}</Fragment>}
		</FormattedMessage>
	);
};

const mapToProps = ({
	language, languages, changeLanguage, mainData,
}) => ({
	language,
	languages,
	changeLanguage,
	mainData,
});
export default withRouter(
	connect(
		mapToProps,
		actions,
	)(Navbar),
);

export { Navbar };
