const defaultSupportedLanguage = {
	value: 'en',
	label: 'English'
};

const supportedLanguages = [
	defaultSupportedLanguage,
	{
		value: 'pt',
		label: 'Português'
	}
];

export { defaultSupportedLanguage, supportedLanguages };
