import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const ContactUsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "contact-us-page" */ './contact-us.page.js'),
	modules: ['./contact-us.page.js'],
	webpack: () => [require.resolveWeak('./contact-us.page.js')],
	loading: CustomLoadableLoading,
});

export default ContactUsPageAsync;
