import React, { Fragment } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';

import { actions } from '../shared/store.js';
import MainService from '../main-service.js';

import './footer.scss';

const FooterCopyright = () => (
	<Fragment>{`© Green Innovations ${new Date().getFullYear()}`}</Fragment>
);

class Footer extends React.Component {
	static prefetchData = async language => MainService.getContactsData(language);

	componentDidMount() {
		const { mainData } = this.props;

		if (!mainData) {
			this.fetchData();
		}
	}

	fetchData() {
		const { language, setMainData } = this.props;
		Footer.prefetchData(language.value)
			.then(data => {
				setMainData(data);
			})
			.catch(error => {
				console.log(error);
			});
	}

	render() {
		const { mainData } = this.props;

		return (
			<div className="footer-wrapper">
				<div className="container container-fluid footer-0-overflow">
					<div className="list">
						<Link to="/" exact>
							<FormattedMessage id="navigation.homepage">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<Link to="/about-us">
							<FormattedMessage id="navigation.about-us">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<Link to="/portfolio">
							<FormattedMessage id="navigation.portfolio">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<Link to="/partner-with-us">
							<FormattedMessage id="navigation.partner-with-us">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<Link to="/stories">
							<FormattedMessage id="navigation.stories">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<Link to="/contact-us">
							<FormattedMessage id="navigation.contact-us">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						{mainData && (
							<a
								href={`mailto:${mainData.email.replace(/\s/g, '')}`}
								className="right"
							>
								{mainData.email}
							</a>
						)}
					</div>
					<div className="list">
						<Link to="\">
							<FormattedMessage id="footer.privacy">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<Link to="\">
							<FormattedMessage id="footer.terms">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Link>
						<span className="right">
							<FooterCopyright year={new Date().getFullYear()} />
						</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapToProps = ({ language, languages, changeLanguage, mainData }) => ({
	language,
	languages,
	changeLanguage,
	mainData,
});

export default connect(
	mapToProps,
	actions,
)(Footer);

export { Footer };
