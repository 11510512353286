import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const StoryDetailsPageAsync = Loadable({
	loader: () =>
		import(/* webpackChunkName: "story-details-page" */ './story-details.page.js'),
	modules: ['./story-details.page.js'],
	webpack: () => [require.resolveWeak('./story-details.page.js')],
	loading: CustomLoadableLoading,
});

export default StoryDetailsPageAsync;
