import React from 'react';
import { Route } from 'react-router-dom';
import { Switch, withRouter } from 'react-router';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { Helmet } from 'react-helmet';

import { actions } from './shared/store.js';

import Navbar from './layout/navbar.js';
import Footer from './layout/footer.js';
import Loading from './components/loading/loading.js';
import NotFound from './components/not-found/page-not-found.js';
import NotImplemented from './components/not-implemented/not-implemented.js';
import HomePageAsync from './features/home/home.page.async.js';
import AboutUsPageAsync from './features/about-us/about-us.page.async.js';
import PortfolioPageAsync from './features/portfolio/portfolio.page.async.js';
import PartnerPageAsync from './features/portfolio/partner/partner.page.async.js';
import ContactUsPageAsync from './features/contact-us/contact-us.page.async.js';
import PartnerWithUsPageAsync from './features/partner-with-us/partner-with-us.page.async.js';
import StoriesPageAsync from './features/stories/stories.page.async.js';
import StoryDetailsPageAsync from './features/stories/story-details.page.async.js';

const pageWrapperStyle = {
	background: 'transparent',
	overflowX: 'hidden',
	paddingBottom: '0px',
	minHeight: '100vh',
};

class App extends React.Component {
	state = {
		navbarOpen: false,
	};

	componentDidUpdate(prevProps) {
		const { location } = this.props;

		if (location !== prevProps.location) {
			window.scrollTo({
				top: 0,
			});
		}
	}

	toggleNavbar = () => {
		this.setState(prevState => ({
			navbarOpen: !prevState.navbarOpen,
		}));
	};

	closeNavbar = () => {
		this.setState({
			navbarOpen: false,
		});
	};

	render() {
		const { isLoading } = this.props;
		const { navbarOpen } = this.state;

		return (
			<div className="app-shell">
				<Helmet defaultTitle="Green" titleTemplate="Green - %s">
					<meta charSet="utf-8" />
					<meta name="type" content="website" />

					<meta property="og:type" content="website" />
					<meta
						property="og:image"
						content="http://green-cms-production.cpr.brightalgo.tech/storage/uploads/2019/09/18/5d8243f0d37a9green-innovations.png"
					/>
					<meta property="og:image:width" content="400" />
					<meta property="og:image:height" content="500" />
				</Helmet>

				{!isLoading && (
					<Navbar
						navbarOpen={navbarOpen}
						toggleNav={this.toggleNavbar}
						closeNavbar={this.closeNavbar}
					/>
				)}

				{isLoading && <Loading />}

				<div style={pageWrapperStyle} className="page-wrapper">
					<Switch>
						<Route exact path="/">
							<HomePageAsync />
						</Route>
						<Route exact path="/about-us">
							<AboutUsPageAsync />
						</Route>
						<Route exact path="/portfolio">
							<PortfolioPageAsync />
						</Route>
						<Route path="/portfolio/:slug" component={PartnerPageAsync} />
						<Route exact path="/contact-us">
							<ContactUsPageAsync />
						</Route>
						<Route exact path="/partner-with-us">
							<PartnerWithUsPageAsync />
						</Route>
						<Route exact path="/stories">
							<StoriesPageAsync />
						</Route>
						<Route path="/stories/:slug" component={StoryDetailsPageAsync} />
						<Route exact path="/404" component={NotFound} />
						<Route component={NotFound} />
						<Route
							exact
							path="/portfolio/not-implemented"
							component={NotImplemented}
						/>
					</Switch>
				</div>

				<div>
					<Footer />
				</div>
			</div>
		);
	}
}

const mapToProps = ({ isLoading }) => ({
	isLoading,
});

export default withRouter(
	connect(
		mapToProps,
		actions,
	)(App),
);
