import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { PrimaryButton } from '../ui-toolkit/buttons.js';

import './not-implemented.scss';

const NotImplemented = () => (
	<div className="not-implemented-wrapper">
		<div className="not-implemented-content">
			<span className="code"><FormattedMessage id="coming-soon.title" /></span>
			<p className="title"><FormattedMessage id="coming-soon.message" /></p>
			<a href="/portfolio">
				<PrimaryButton>
					<FormattedMessage id="coming-soon.button_label" />
				</PrimaryButton>
			</a>
		</div>
	</div>
);

export default injectIntl(NotImplemented);
