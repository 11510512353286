import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { Provider, connect } from 'redux-zero/react';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en.js';
import pt from 'react-intl/locale-data/pt.js';

import { initializeStoreClient, actions } from './shared/store.js';

import App from './app.js';

// import the i18n messages
import I18NLanguages from './i18n/index.js';

// TODO: get the supports languages from the CMS
import {
	supportedLanguages,
	defaultSupportedLanguage
} from './shared/supported-languages.js';

import './index.scss';

addLocaleData([...en, ...pt]);

function getCookie(name) {
	const nameEQ = `${name}=`;
	const ca = window.document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

const mapToProps = ({ language, languages }) => ({
	language,
	languages
});

const intlMessages = I18NLanguages.reduce((result, language) => {
	// eslint-disable-next-line no-param-reassign
	result[language.code] = language.value;
	return result;
}, {});

const AppWrapper = connect(
	mapToProps,
	actions
)(props => (
	<IntlProvider
		locale={props.language.value}
		messages={intlMessages[props.language.value]}
	>
		<Router>
			<App />
		</Router>
	</IntlProvider>
));

const StoreAppWrapper = () => (
	<Provider
		store={initializeStoreClient({
			language:
				supportedLanguages.find(
					language => language.value === getCookie('_language')
				) || defaultSupportedLanguage,
			languages: supportedLanguages,
			// eslint-disable-next-line no-underscore-dangle
			...window.__APP_STATE__
		})}
	>
		<AppWrapper />
	</Provider>
);

// eslint-disable-next-line no-underscore-dangle
if (window.__SSR__) {
	window.onload = () => {
		Loadable.preloadReady().then(() => {
			ReactDOM.hydrate(<StoreAppWrapper />, document.getElementById('root'));
		});
	};
} else {
	ReactDOM.render(<StoreAppWrapper />, document.getElementById('root'));
}
