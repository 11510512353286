import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';


import './not-found.scss';

const PageNotFound = () => (
	<div className="container container-fluid">
		<div className="not-found-wrapper">
			<div className="not-found-content">
				<div className="">
					<span className="code">404</span>
					<p className="title"><FormattedMessage id="not-found.label" /></p>
				</div>
			</div>
		</div>
	</div>
);

export default injectIntl(PageNotFound);
