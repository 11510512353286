import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../../components/loading/loading.js';

const PartnerPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "partner-page" */ './partner.page.js'),
	modules: ['./partner.page.js'],
	webpack: () => [require.resolveWeak('./partner.page.js')],
	loading: CustomLoadableLoading,
});

export default PartnerPageAsync;
