import createStore from 'redux-zero';

function setCookie(name, value, days) {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	window.document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

const initialStore = {
	isLoading: false,
	homepageData: null,
	homepageNews: null,
	homepagePartners: null,
	mainData: null,
	aboutUs: null,
	portfolioPageData: null,
	portfolioPartners: null,
	portfolioCategories: null,
	partnerData: null,
	partnerNewsData: null,
	contactUs: null,
	partnerWithUs: null,
	storiesCategories: null,
	storiesData: null,
	storiesList: null,
	storyDetails: null,
	relatedStoryDetail: null,
	homeStoriesList: null,
};

function initialState({ language, languages, ...props }) {
	return {
		languages,
		language,
		...initialStore,
		...props,
	};
}

const actions = () => ({
	changeLanguage: (state, selectedLanguage) => {
		setCookie('_language', selectedLanguage.value, 90);

		return { ...initialStore, language: selectedLanguage };
	},

	setLoading: (state, value) => ({
		isLoading: value,
	}),
	setHomepageData: (state, value) => ({
		homepageData: value,
	}),
	setHomepageNews: (state, value) => ({
		homepageNews: value,
	}),
	setHomepagePartners: (state, value) => ({
		homepagePartners: value,
	}),
	setMainData: (state, value) => ({
		mainData: value,
	}),
	setAboutUs: (state, value) => ({
		aboutUs: value,
	}),
	setPortfolioPageData: (state, value) => ({
		portfolioPageData: value,
	}),
	setPortfolioPartners: (state, value) => ({
		portfolioPartners: value,
	}),
	setPortfolioCategories: (state, value) => ({
		portfolioCategories: value,
	}),
	setPartnerData: (state, value) => ({
		partnerData: value,
	}),
	setPartnerNewsData: (state, value) => ({
		partnerNewsData: value,
	}),
	setContactUs: (state, value) => ({
		contactUs: value,
	}),
	setPartnerWithUs: (state, value) => ({
		partnerWithUs: value,
	}),
	setStoriesData: (state, value) => ({
		storiesData: value,
	}),
	setStoriesCategories: (state, value) => ({
		storiesCategories: value,
	}),
	setStoriesList: (state, value) => ({
		storiesList: value,
	}),
	setHomeStoriesList: (state, value) => ({
		homeStoriesList: value,
	}),
	setStoryDetails: (state, value) => ({
		storyDetails: value,
	}),
	setRelatedStoryDetail: (state, value) => ({
		relatedStoryDetail: value,
	}),
});

let store = null;

function initializeStoreClient(state) {
	store = createStore(initialState(state));
	return store;
}

function initializeStoreServer(state) {
	store = createStore(initialState(state));
	return store;
}

export {
	initializeStoreClient, initializeStoreServer, store, actions,
};
