import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const PortfolioPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "portfolio-page" */ './portfolio.page.js'),
	modules: ['./portfolio.page.js'],
	webpack: () => [require.resolveWeak('./portfolio.page.js')],
	loading: CustomLoadableLoading,
});

export default PortfolioPageAsync;
