import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const StoriesPageAsync = Loadable({
	loader: () =>
		import(/* webpackChunkName: "stories-page" */ './stories.page.js'),
	modules: ['./stories.page.js'],
	webpack: () => [require.resolveWeak('./stories.page.js')],
	loading: CustomLoadableLoading,
});

export default StoriesPageAsync;
