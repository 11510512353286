import Loadable from 'react-loadable';

import { CustomLoadableLoading } from '../../components/loading/loading.js';

const PartnerWithUsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "partner-with-us-page" */ './partner-with-us.page.js'),
	modules: ['./partner-with-us.page.js'],
	webpack: () => [require.resolveWeak('./partner-with-us.page.js')],
	loading: CustomLoadableLoading,
});

export default PartnerWithUsPageAsync;
